import { Component, OnInit, OnDestroy,  Input, Output, EventEmitter, HostListener, ElementRef, ViewChild } from '@angular/core';
import {  catchError, takeUntil } from 'rxjs/operators';
import {  of } from 'rxjs';
import {  DataComponentState } from 'libs/models/pbc-models';
import { BaseContentPage } from 'libs/classes/base-content-page';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { SeverityLevel } from 'libs/services/logging.service';
import { NotificationsResponse } from 'libs/models/notifications.model';
import { NotificationsService } from 'libs/services/notifications.service';
import { EnvironmentService } from 'libs/services/environment-variables.service';
import { MemberService } from 'member/shared/services/member.service';
import { baseenvironment } from 'libs/environments/environment-base';

@Component({
  selector: 'pbc-notification-center',
  templateUrl: './notification-center.component.html',
  styles: [
  ]
})
export class NotificationCenterComponent extends BaseContentPage implements OnInit, OnDestroy {
  notificationCenterContent: DataComponentState;
  notifyInstance = 'content-notification-center';
  notifyInstancetype = 'member-dashboard';
  private lastFocusedElement: HTMLElement;
  @ViewChild('firstElement') firstElement: ElementRef;
  @ViewChild('lastElement') lastElement: ElementRef;
  @Input() notificationDetails: NotificationsResponse[];
  @Input() notificationCount: number;
  updatedNotificationDetails: NotificationsResponse[] = [];
  saveNotificationDetail: NotificationsResponse[] = [];
  hasData: boolean = false;
  message = null;
  nullMessage = "Currently, there are no notifications.";
  notifyCheck = false;
  hasError: boolean = false;
  @Output() closePop = new EventEmitter();
  @Output() counter = new EventEmitter();
  featureFlags: any;
  blockAction = false;
  userId: string;

  constructor(
    tabTitle: Title,
    activatedRoute: ActivatedRoute,   
    private readonly notificationService: NotificationsService,
    private readonly memberService: MemberService
  ) {
    super(false, tabTitle, activatedRoute);
    this.featureFlags = EnvironmentService.variables.featureFlags;
  }
  ngOnInit(): void {
    this.lastFocusedElement = document.activeElement as HTMLElement;
    const pageData = this.activatedRoute.snapshot.data;
    if (pageData) {
      this.contentService.getComponentState(this.notifyInstance, this.notifyInstancetype)
        .pipe(takeUntil(this.componentDestroyed$), catchError(err => this.handleError(err)))
        .subscribe(response => {
          if (response != null && !!response)
            this.notificationCenterContent = response as DataComponentState;
        });

    }
    if (this.notificationDetails?.length > 0) {
      this.message = this.notificationCount + " new";
    } else {
      this.message = "0 new";
      this.notifyCheck = true;
    }
    this.memberService.getImpersonation().pipe(takeUntil(this.componentDestroyed$)).subscribe(result => {
      this.blockAction = result.isImpersonating;
      this.userId = result.userId;
    });
  }

  readNotificationChange(index: number, notify: NotificationsResponse) {
    if (notify.read == false && !this.blockAction) {
      if (!!sessionStorage && sessionStorage.length > 0 && !!sessionStorage.getItem("updatedNotificationDetails"))
        this.updatedNotificationDetails = JSON.parse(sessionStorage.getItem("updatedNotificationDetails"));
      let changedNotification: NotificationsResponse;
      changedNotification = notify;
      changedNotification.read = true;
      this.updatedNotificationDetails.push(changedNotification);
      sessionStorage.setItem('updatedNotificationDetails', JSON.stringify(this.updatedNotificationDetails));
      this.notificationDetails.splice(index, 1, changedNotification);
      this.LoadNotification();
      this.notificationCount = this.notificationCount - 1;
      this.message = this.notificationCount + " new";
      this.counter.emit(this.notificationCount);
    }
  }

  LoadNotification() {
    if (this.notificationDetails.length == 0) {
      this.notifyCheck = true;
      this.ngOnInit();
    }
  }
  deleteNotification(index: number, notify: NotificationsResponse) {
    if (!this.blockAction) {
      if (!!sessionStorage && sessionStorage.length > 0 && !!sessionStorage.getItem("updatedNotificationDetails"))
        this.updatedNotificationDetails = JSON.parse(sessionStorage.getItem("updatedNotificationDetails"));
      notify.hidden = true;
      this.updatedNotificationDetails.push(notify);
      sessionStorage.setItem('updatedNotificationDetails', JSON.stringify(this.updatedNotificationDetails));
      this.notificationDetails.splice(index, 1);
      this.LoadNotification();
      if (!notify.read) {
        this.notificationCount = this.notificationCount - 1;
        this.message = this.notificationCount + " new";
      }
      if (this.notificationCount == 0) {
        this.nullMessage = "All of your notifications were deleted.";
      }
      this.counter.emit(this.notificationCount);
    }
    else {
      this.logger.track(`Impersonator attempted to delete notification but was blocked.`, { 'Impersonator ': this.userId }, SeverityLevel.Warning);
      return;
    }
  }
  private handleError(error: Error) {
    this.logger.trackException(error, this.router.url, SeverityLevel.Error);
    this.hasError = true;
    return of(null);
  }
  public updateNotification(updatedNotificationDetails: NotificationsResponse[]) {
    this.notificationService.updateNotification(updatedNotificationDetails).pipe(takeUntil(this.componentDestroyed$),
      catchError(err => {
        console.log(err);
        this.logger.trackException(err, 'NotificationLoadingError in Header', SeverityLevel.Error);
        return of(null);
      })).subscribe((response) => {
        if (!!response) {
          // this.notificationDetails = response;
          //this.notificationCount = this.notificationDetails.filter(x => x.read == false).length;
        }
      });
  }
  public closePopover() {
    if (!!sessionStorage && sessionStorage.length > 0 && !!sessionStorage.getItem("updatedNotificationDetails")) {
      this.saveNotificationDetail = JSON.parse(sessionStorage.getItem("updatedNotificationDetails"));
      if (!!this.saveNotificationDetail) {
        this.updateNotification(this.saveNotificationDetail);
        sessionStorage.setItem('updatedNotificationDetails', JSON.stringify([]));
      }
    }
    this.closePop.emit();
    this.lastFocusedElement.focus();
  }

  onViewReferral(notify: NotificationsResponse) {
    if (!this.blockAction) {
      sessionStorage.setItem('notificationPlanSwitching', JSON.stringify(notify));
      this.router.navigateByUrl("/manage-referrals", { state: { data: { notificationMemberKey: notify.recipient.memberKey } } });
      this.closePopover();
    }
    else {
      this.router.navigateByUrl(this.router.url);
    }

  }
  onViewMemberPCPChange(notify: NotificationsResponse) {
    if (!this.blockAction) {
      this.router.navigateByUrl("/primary-care-provider", { state: { data: { notificationMemberKey: notify.recipient.memberKey, notificationPlan: notify.plan, ppoNotificationClick: notify.plan  } } });
      this.closePopover();
    } else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  onViewPriorAuth(notify: NotificationsResponse) {
    if (!this.blockAction) {
      sessionStorage.setItem('notificationPlanSwitching', JSON.stringify(notify));
      this.router.navigateByUrl("/preapprovals", { state: { data: { notificationMemberKey: notify.recipient.memberKey } } });
      this.closePopover();
    }
    else {
      this.router.navigateByUrl(this.router.url);
    }

  }

  onViewKinwellConnect(notify: NotificationsResponse) {
    if (!this.blockAction) {
      window.open(baseenvironment.notification.kinwellURL, "_blank");
      this.closePopover();
    }
    else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  onViewHMOWelcome(notify: NotificationsResponse) {
    if (!this.blockAction) {
      sessionStorage.setItem('notificationPlanSwitching', JSON.stringify(notify));
      window.location.href = `${baseenvironment.baseUrlVisitor}/visitor/hmo-welcome`;
      this.closePopover();
    }
    else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  onViewManageClaims(notify: NotificationsResponse) {
    if (!this.blockAction) {
      this.router.navigateByUrl("/manage-claims");
      this.closePopover();
    }
    else {
      this.router.navigateByUrl(this.router.url);
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the Tab key was pressed
    if (event.key === 'Tab' || event.keyCode === 9) {
      // If the last element is focused and the Tab key is pressed, focus the first element
      if (document.activeElement === this.lastElement.nativeElement && !event.shiftKey) {
        this.firstElement.nativeElement.focus();
        event.preventDefault();
      }

      // If the first element is focused and the Shift+Tab keys are pressed, focus the last element
      if (document.activeElement === this.firstElement.nativeElement && event.shiftKey) {
        this.lastElement.nativeElement.focus();
        event.preventDefault();
      }
    }
  }

}
