import { baseenvironment } from 'libs/environments/environment-base';

const hostnames = {
  secureAuth: 'https://premera-test.saas.secureauth.com',
  bffApi: 'https://member-edge-preprod.premera.com/api/',
  contentApi: 'https://contentservices-acpt.premera.com/api/',
  //contentApi: 'https://app-content-service-digweb-staging-westus2-01.ase-ecp-staging-aseilbv3-02.appserviceenvironment.net/api/',
  visitor: 'https://visitor-preprod.premera.com',
  member: 'https://member-preprod.premera.com',
  employer: 'https://www.premera.acpt/{region}/employer/',
  producer: 'https://www.premera.acpt/{region}/producer/',
  provider: 'https://www.premera.acpt/{region}/provider/'
};

export let environment = baseenvironment;

// Overwrites for this environment
environment.site = 'visitor';
environment.name = 'preprod';
environment.appId = 'member-preprod';
environment.production = true;
environment.version = 'PLZ-Visitor-External_2024.11.1.2';
environment.contentDomain = '//www.premera.acpt';
environment.egainDomain = 'premeradev';
environment.appInsightsKey = '5e61dac5-4e08-47a1-ad7c-a78999b7026b';
environment.configServiceUrl = '';
environment.baseUrlVisitor = `${hostnames.visitor}`;
environment.baseUrlMember = `${hostnames.member}`;
environment.baseUrlEmployer = `${hostnames.employer}`;
environment.baseUrlProducer = `${hostnames.producer}`;
environment.baseUrlProvider = `${hostnames.provider}`;

environment.dataLocation.bffBaseUrl = `${hostnames.bffApi}`;
environment.dataLocation.contentBaseUrl = `${hostnames.contentApi}`;

environment.authentication.clientId = '976c40a075d4400487c4c8c0cb18edc7';
environment.authentication.callbackUrl = `${hostnames.member}/callback`;
environment.authentication.registerUrl = `${hostnames.secureAuth}/SecureAuth30/Register.aspx`;
environment.authentication.authorization = `${hostnames.secureAuth}/SecureAuth30/SecureAuth.aspx`;
environment.authentication.restart = `${hostnames.secureAuth}/SecureAuth30/Restart.aspx?ReturnStr=${hostnames.visitor}`;

environment.recoveryEmail.url = `${hostnames.secureAuth}/SecureAuth40/Authorized/accountupdate.aspx`;
environment.recoveryPhone.url = `${hostnames.secureAuth}/SecureAuth41/Authorized/accountupdate.aspx`;
environment.resetPassword.url = `${hostnames.secureAuth}/SecureAuth42/SecureAuth.aspx`;
environment.addPlan.url = `${hostnames.secureAuth}/SecureAuth30/Customized/AddAPlan.aspx`;

// if a feature flag is different for this environment, put it here like this:
environment.featureFlags.chat = true;
environment.featureFlags.configService = false;
