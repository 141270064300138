<div (pbcCloseDropdown)="close($event)">
  <ul id="secondary-nav" aria-label="Secondary Navigation" class="navbar-nav nav-secondary">
    <li class="nav-item" *ngIf="!isAuthenticated && site!=='lwwa'" >
      <a data-id="signInMenuButton" class="btn btn-secondary mr-sm-0" [href]="signInUrl" tabindex="0" pbcAnalyticsTag
        data-analytics-override-route="header">  
        <strong>{{ signInText }}</strong>
      </a>
    </li>
    <li class="nav-item" *ngIf="!isAuthenticated && site ==='lwwa'" >
      <a data-id="signInMenuButton" class="btn btn-secondary-light" [href]="signInUrl" tabindex="0" pbcAnalyticsTag
        data-analytics-override-route="header">  
        <strong>{{ signInText }}</strong>
      </a>
    </li>
    <li class="nav-item" *ngIf="isAuthenticated" placement="bottom-right" (click)="toggleMenu = !toggleMenu" aria-label="submenu" aria-live="polite">
      <a aria-haspopup="true" [attr.aria-expanded]="toggleMenu" class="nav-link dropdown-toggle" id="accountMenu"
        tabindex="0" pbcAnalyticsTag data-analytics-override-route="header"
        data-analytics-title="user" [attr.aria-label]="content?.myAccountText">
        <i class="far fa-user-circle" [ngClass]="lwwaIcnCls" aria-hidden="true"></i> <strong>{{ content?.myAccountText }}</strong>
      </a>
      <ul *ngIf="toggleMenu" id="account-nav" aria-label="Account Links">
        <li *ngFor="let menuItem of accountMenuLinks | groupBy: 'linkText'; last as isLast;">
          <a *ngIf="!isLast" class="dropdown-item" routerLink="{{menuItem.value[0].route}}" tabindex="0"
            pbcAnalyticsTag data-analytics-override-route="header">
            {{ menuItem.value[0].linkText }}
          </a>
          <a *ngIf="isLast" class="dropdown-item" (click)="signOut()" tabindex="0"
            pbcAnalyticsTag data-analytics-override-route="header">
            {{ menuItem.value[0].linkText }}
          </a>
        </li>
      </ul>
    </li>
  </ul>
  <div *ngIf="!hideRegionSwitch && site!=='lwwa'"  class="switch-lob-container d-flex">
    <ng-container *ngIf="serverName=='mock' || serverName=='demo'">
      <select (change)="changeMember($event.target.value,$event.target.selectedOptions[0].text)" class="demoDropdown">
        <option [selected]="!demoserverHmoFlag && !demoserverFutureHmoFlag" value="false">PPO</option>
        <option [selected]="demoserverHmoFlag && !demoserverFutureHmoFlag" value="true">HMO</option>
        <option [selected]="demoserverHmoFlag && demoserverFutureHmoFlag" value="true">FHMO</option>
      </select>
    </ng-container>
    <a pbcAnalyticsTag data-analytics-override-route="footer" data-analytics-title="footer lob" tabindex="0" href onclick="return false" (click)="switchLob()">
      <span id="footer-lob">{{ switchContent?.content?.primarySwitchStateButton }}</span>
    </a>
  </div>
</div>
